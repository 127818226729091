.App {
    font-family: sans-serif;
    text-align: center;
    margin-top: 200px;
  }

a {

    text-decoration: none;
}

::-ms-reveal {
  display: none !important;
}
  